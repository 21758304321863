import React from "react";
import Header from "../header";
import Sidebar from "../sidebar";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-1">
        <Header />
      </div>
      <div className="grid grid-cols-12 md:mt-28 mt-36">
        <div className="col-span-2 md:block hidden">
          <Sidebar />
        </div>
        <div className="md:col-span-10 col-span-12 md:px-10">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
