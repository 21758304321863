import React, { useState } from "react";
import { json, useNavigate } from "react-router-dom";
import axiosClient from "../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOpen1 = () => {
    setIsOpen1(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    if (loginData.email === "" || loginData.password === "") {
      handleOpen();
    } else if (loginData.email && !validateEmail(loginData.email)) {
      handleOpen1();
    } else {
      setLoader(true);
      axiosClient()
        .post("/user/adminLogin", loginData)
        .then((res) => {
          toast.success(res.data.message);
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.token);
          setLoader(false);
          navigate("/user");
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
          return error;
        });
    }
  };

  const handleLoginFields = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleClose1 = () => {
    setIsOpen1(false);
  };
  return (
    <React.Fragment>
      <div className="">
        <div className="grid grid-cols-12">
          <div className="md:col-span-6 col-span-12 bg-white">
            <div className="sm:px-32 px-5">
              <div className="flex justify-center items-center">
                <div className="sm:mt-20 mt-3">
                  <img
                    src="/Group 1163.png"
                    alt="logo"
                    width={250}
                    height={100}
                  />
                </div>
              </div>
              <div className="bg-[#DFDFDF]  mt-6 h-[1px] "></div>
              <div>
                <p className="text-[32px] font-[700] font-sans py-3">
                  Sign In{" "}
                </p>

                <form className="my-3">
                  <div className="mb-6">
                    <label
                      for="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={loginData.email}
                      onChange={handleLoginFields}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="john.doe@company.com"
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      for="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={loginData.password}
                      onChange={handleLoginFields}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="•••••••••"
                      required
                    />
                  </div>

                  <div className="flex items-start mb-6">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        required
                      />
                    </div>
                    <label
                      for="remember"
                      className="ml-2   text-[16px] font-medium text-gray-900 dark:text-gray-300"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="bg-[#05164D] w-full flex justify-center items-center">
                    <button
                      // onClick={() => navigate("/home")}
                      onClick={handleLogin}
                      disabled={loader}
                      className="text-white text-center text-[16px] focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto  py-2.5   "
                    >
                      {loader ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="w-6 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "LOGIN"
                      )}
                    </button>
                  </div>
                  <div className=" text-[16px] mb-7 py-4 flex justify-between">
                    <p className="text-[#05164D] text-[16px] font-bold">
                      Forgot Password
                    </p>
                    <p className="text-[#05164D] text-[16px] font-bold">
                      Register
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className=" hidden col-span-6 sm:flex justify-end pt-[60px]"
            style={{
              backgroundImage: "url(/s.PNG)",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
              repeat: "no-repeat",
            }}
          >
            <img src="/browser.png" alt="image" className="h-[560px]" />
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 flex items-center justify-center z-20 ${
          isOpen1 ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300 ${
          isClosing ? "ease-in" : "ease-out"
        }`}
      >
        <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div className="bg-white rounded-lg font-sans p-4 z-50 w-[400px]">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-[#F0AD02]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>

            <h2 className="text-lg font-semibold mb-4 ml-4">Attention</h2>
          </div>
          <p className="mb-4">Email format is not correct</p>
          <button
            className="bg-[#F0AD02]  text-white font-bold py-2 px-4 rounded"
            onClick={handleClose1}
          >
            Okay!
          </button>
        </div>
      </div>
      <div
        className={`fixed inset-0 flex items-center justify-center z-20 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300 ${
          isClosing ? "ease-in" : "ease-out"
        }`}
      >
        <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div className="bg-white rounded-lg font-sans p-4 z-50 w-[400px]">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-[#F0AD02]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>

            <h2 className="text-lg font-semibold mb-4 ml-4">Attention</h2>
          </div>
          <p className="mb-4">
            Please fill the form carefully. Some fields are not completed
            correctly
          </p>
          <button
            className="bg-[#F0AD02]  text-white font-bold py-2 px-4 rounded"
            onClick={handleClose}
          >
            Okay!
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
