import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import User from "./pages/user";
import EditUser from "./pages/edit-user";
import CreateUser from "./pages/create-user";
import Payments from "./pages/payments";
import UpdateSubscription from "./pages/update-subscription";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/user" element={<User />} />
          <Route path="/edit-user/:id" element={<EditUser />} />
          <Route path="/create-user" element={<CreateUser />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/update-subscription" element={<UpdateSubscription />} />
        </Routes>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
