import React, { useState } from "react";
import { MdDashboard } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdCardGiftcard } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { LuLogIn } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <React.Fragment>
      <div className="md:flex flex-col md:flex-row md:min-h-screen fixed">
        <div className="flex flex-col w-fit lg:pl-5  text-gray-700  dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">
          <div className="max-w-[250px] w-[100%]">
            <nav
              className={` flex-grow md:block lg:px-4 px-2 pb-4 md:pb-0 md:overflow-y-auto ${
                isOpen ? "block" : "hidden"
              }`}
            >
              {/* <p
                onClick={() => navigate("/home")}
                className={`cursor-pointer flex items-center px-4 py-2 mt-2 text-sm ${
                  location.pathname === "/home"
                    ? "text-[#FFAD00] font-[700]"
                    : "text-gray-900"
                }  bg-transparent rounded-lg   hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
              >
                <MdDashboard className="h-[17px] w-[17px] text-[100px]" />
                <span className="ml-2 "> Dashboard</span>
              </p> */}
              <div className="relative">
                <div
                  onClick={toggleDropdown}
                  className="flex  items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:block hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                >
                  <span className="cursor-pointer flex items-center justify-between">
                    <div className="flex items-center">
                      <FaUserAlt className="h-[17px] w-[17px] text-[100px]" />
                      <span className="ml-3 text-sm">User</span>
                    </div>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${
                        isDropdownOpen ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  className={` right-0 w-full mt-2 origin-top-right  ${
                    isDropdownOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="pl-6 py-2 ">
                    <p
                      onClick={() => navigate("/user")}
                      className={` ${
                        location.pathname === "/user"
                          ? "text-[#FFAD00] font-bold"
                          : "text-gray-900"
                      } cursor-pointer block px-4 py-2 mt-2 text-sm  bg-transparent rounded-lg  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
                    >
                      User List
                    </p>
                    <p
                      onClick={() => navigate("/create-user")}
                      className={`${
                        location.pathname === "/create-user"
                          ? "text-[#FFAD00] font-bold"
                          : "text-gray-900"
                      } cursor-pointer block px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
                    >
                      Create User
                    </p>
                  </div>
                </div>
              </div>
              <p
                onClick={() => navigate("/payments")}
                className={`${
                  location.pathname === "/payments"
                    ? "text-[#FFAD00] font-bold"
                    : "text-gray-900"
                }  cursor-pointer flex items-center px-4 py-2 mt-2 text-sm  text-gray-900 bg-transparent rounded-lg  hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
              >
                <MdCardGiftcard className="h-[17px] w-[17px] text-[100px]" />
                <span className="ml-2 "> payments</span>
              </p>
              <p
                onClick={() => navigate("/update-subscription")}
                className={`${
                  location.pathname === "/update-subscription"
                    ? "text-[#FFAD00] font-bold"
                    : "text-gray-900"
                } cursor-pointer flex items-center px-4 py-2 mt-2 text-sm  bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
              >
                <IoMdSettings className="h-[17px] w-[17px] text-[100px]" />

                <span className="ml-2 "> Update Subscription</span>
              </p>
              <p
                onClick={() => navigate("/")}
                className="cursor-pointer flex items-center px-4 py-2 mt-2 text-sm  text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              >
                <LuLogIn className="h-[17px] w-[17px] text-[100px]" />
                <span className="ml-2"> Logout</span>
              </p>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
