import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import axiosClient from "../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StripData from "../../components/strip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51L8gJIGcwuk8geTI0E5zDxZ1Vw0YlwkeGu6tPGlmejGVTGfwf7Kptw43B1IRmjPnWVdHJ8DvYO4DnPrGHDBj11oq00dXP7FMim"
);

const UpdateSubscription = () => {
  const [allUser, setAllUser] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState({
    loader: "",
    index: "",
  });
  const navigate = useNavigate();
  const itemsPerPage = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allUser.slice(indexOfFirstItem, indexOfLastItem);

  // Handle next page click
  const nextPage = () => {
    const lastPage = Math.ceil(allUser.length / itemsPerPage);
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle previous page click
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    axiosClient()
      .get("/user/allUsers")
      .then((res) => {
        console.log("res", res.data.data);
        setAllUser(res.data.data);
      })
      .catch((error) => {
        return error;
      });
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = (data) => {
    setUserId(data);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Layout>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            {/* Table headers */}
            <thead className="bg-gray-100">
              <tr>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  Email
                </th>
                <th scope=""></th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-4 px-6 text-sm text-gray-900">
                    {item.first_name}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-500">
                    {item.last_name}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-900">
                    {item.email}
                  </td>
                  <td class="py-4 flex px-6 text-sm font-medium text-right whitespace-nowrap">
                    <p
                      onClick={() => handleOpenModal(item)}
                      class="text-blue-600 cursor-pointer hover:underline"
                    >
                      update
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination buttons */}
          <div className="flex justify-end mt-4">
            <button
              onClick={previousPage}
              disabled={currentPage === 1}
              className="py-2 px-4 border border-gray-300 rounded-md mr-2"
            >
              Previous
            </button>
            <button
              onClick={nextPage}
              disabled={indexOfLastItem >= allUser.length}
              className="py-2 px-4 border border-gray-300 rounded-md"
            >
              Next
            </button>
          </div>
        </div>
        {/* <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                  Email
                </th>
                <th scope=""></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {allUser.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-4 px-6 text-sm text-gray-900">
                    {item.first_name}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-500">
                    {item.last_name}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-900">
                    {item.email}
                  </td>
                  <td class="py-4 flex px-6 text-sm font-medium text-right whitespace-nowrap">
                    <p
                      onClick={() => handleOpenModal(item)}
                      class="text-blue-600 cursor-pointer hover:underline"
                    >
                      update
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        {/* <div class="w-full mx-auto">
          <div class="flex flex-col">
            <div class="overflow-x-auto shadow-md sm:rounded-lg">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                  <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                    <thead class="bg-gray-100 ">
                      <tr>
                        <th scope="col" class="p-4"></th>
                        <th
                          scope="col"
                          class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase "
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase "
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase "
                        >
                          Email
                        </th>
                        <th scope="col" class="p-4">
                          <span class="sr-only">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200  ">
                      {allUser.map((obj, index) => (
                        <tr key={obj} class="hover:bg-gray-100 ">
                          <td class="p-4 w-4"></td>
                          <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap ">
                            {obj?.first_name}
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap ">
                            {obj?.last_name}
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap ">
                            {obj?.email}
                          </td>
                          <td class="py-4  flex px-6 text-sm font-medium text-right whitespace-nowrap">
                            <p
                              onClick={() => handleOpenModal(obj)}
                              class="text-blue-600 cursor-pointer hover:underline"
                            >
                              update
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Modal */}
        {isOpen ? (
          <div className="fixed inset-0  flex items-center justify-center z-20">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity"
              onClick={handleCloseModal}
            ></div>
            <div className="bg-white w-[500px] rounded-lg p-8 z-50">
              <Elements stripe={stripePromise}>
                <StripData userId={userId} setIsOpen={setIsOpen} />
              </Elements>
            </div>
          </div>
        ) : (
          ""
        )}
      </Layout>
    </React.Fragment>
  );
};

export default UpdateSubscription;
