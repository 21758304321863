import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../components/layout";
import axiosClient from "../../helper";

const EditUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editDetails, setEditDetails] = useState({
    company: "",
    vat_id: "",
    first_name: "",
    last_name: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    email: "",
    website: "",
    skype: "",
    telephone: "",
    status: "",
  });
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    setEditDetails({ ...editDetails, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    setLoader(true);

    axiosClient()
      .put(`/user/updateUser/${params.id}`, editDetails)
      .then((res) => {
        toast.success(res.data.message);
        setLoader(false);
        navigate("/user");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoader(false);

        return error;
      });
  };
  useEffect(() => {
    axiosClient()
      .get(`/user/getOne/${params.id}`)
      .then((res) => {
        setEditDetails({
          company: res.data.data?.company,
          vat_id: res.data.data?.vat_id,
          first_name: res.data.data?.sure_name,
          last_name: res.data.data?.last_name,
          address: res.data.data?.address,
          postalCode: res.data.data?.postalCode,
          city: res.data.data?.city,
          country: res.data.data?.country,
          email: res.data.data?.email,
          website: res.data.data?.website,
          skype: res.data.data?.skype,
          telephone: res.data.data?.telephone,
          status: res?.data?.data?.status,
        });
      })
      .catch((error) => {
        return error;
      });
  }, []);
  return (
    <Layout>
      <div>
        <div className="bg-white sm:px-10 px-3 mt-5 py-5 font-sans">
          <p className="text-[24px] font-[700]">Edit User Details</p>
        </div>
        <div className="bg-[#f6f6f6] ">
          <div className="grid grid-cols-12 sm:px-10 px-3 pt-6  sm:space-x-6 ">
            <div className="sm:col-span-6 col-span-12">
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Company name
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter company name"
                  name="company"
                  value={editDetails.company}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  First name
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="John"
                  name="first_name"
                  value={editDetails.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Address
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter Address"
                  name="address"
                  value={editDetails.address}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  City
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter city"
                  name="city"
                  value={editDetails.city}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Email
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter Email"
                  name="email"
                  value={editDetails.email}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  status
                </label>
                <select
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  name="status"
                  value={editDetails.status}
                  onChange={handleChange}
                >
                  <option value="lead">lead</option>
                  <option value="subscriber">subscriber</option>
                  <option value="dormant">dormant</option>
                  <option value="lost">lost</option>
                </select>
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Skype <span className="text-gray-300">(optional)</span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter Skype ID"
                  name="skype"
                  value={editDetails.skype}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="sm:col-span-6 col-span-12">
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  VAT ID
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter VAT ID"
                  name="vat_id"
                  value={editDetails.vat_id}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter last name"
                  name="last_name"
                  value={editDetails.last_name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter postal code"
                  name="postalCode"
                  value={editDetails.postalCode}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Country
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Select counter "
                  name="country"
                  value={editDetails.country}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Website
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter website address"
                  name="website"
                  value={editDetails.website}
                  onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label
                  for="first_name"
                  class="block mb-2 text-[16px] font-[600] text-gray-900 "
                >
                  Telephon <span className="text-gray-300">(optional)</span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter telephon number"
                  name="telephone"
                  value={editDetails.telephone}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:px-10 px-3  pb-10">
            <button
              onClick={handleSubmit}
              disabled={loader}
              className="text-[16px] flex justify-center items-center max-w-[300px] w-[100%] mt-5 font-[700] font-sans py-[11px] px-6 text-white bg-[#05164D]"
            >
              {loader ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-6 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "UPDATE"
              )}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
