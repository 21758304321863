import React, { useState } from "react";

import { MdDashboard } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdCardGiftcard } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { LuLogIn } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const drawerClasses = `fixed inset-0 flex z-50 transition-transform ${
    isDrawerOpen ? "translate-x-0" : "-translate-x-full"
  }`;
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <React.Fragment>
      <div className="bg-[#05164D]  py-[15px] fixed w-[100%] z-10 ">
        <div className="flex justify-between md:hidden lg:px-7  px-3">
          <img src="/logo.svg" alt="" className="h-[46px] " />
          <div className="flex-shrink-0 flex flex-row items-center justify-between">
            <button
              onClick={toggleDrawer}
              className=" focus:outline-none focus:shadow-outline"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                className="w-6 h-6 text-white"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="md:block hidden">
          <div className="grid grid-cols-12">
            <div className="col-span-2 flex pl-10">
              <img src="/logo.svg" alt="" className="h-[46px] " />
            </div>
          </div>
        </div>
      </div>
      <div className={drawerClasses}>
        <div
          className="flex-1 bg-gray-800 bg-opacity-50"
          onClick={toggleDrawer}
        ></div>
        <div className="w-64 bg-white absolute top-0 left-0 h-full">
          <div className="max-w-[250px] w-[100%]">
            <nav
              className={` flex-grow md:block lg:px-4 px-2 pb-4 md:pb-0 md:overflow-y-auto `}
            >
              <div className="relative">
                <div
                  onClick={toggleDropdown}
                  className="flex  items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:block hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                >
                  <span className="cursor-pointer flex items-center justify-between">
                    <div className="flex items-center">
                      <FaUserAlt className="h-[17px] w-[17px] text-[100px]" />
                      <span className="ml-3 text-sm font-bold">User</span>
                    </div>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${
                        isDropdownOpen ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  className={` right-0 w-full mt-2 origin-top-right  ${
                    isDropdownOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="pl-6 py-2 ">
                    <p
                      onClick={() => navigate("/user")}
                      className={` ${
                        location.pathname === "/user"
                          ? "text-[#FFAD00]"
                          : "text-gray-900"
                      } cursor-pointer block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
                    >
                      User Lisr
                    </p>
                    <p
                      onClick={() => navigate("/create-user")}
                      className={`${
                        location.pathname === "/create-user"
                          ? "text-[#FFAD00]"
                          : "text-gray-900"
                      } cursor-pointer block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
                    >
                      Create user
                    </p>
                  </div>
                </div>
              </div>
              <p
                onClick={() => navigate("/payments")}
                className={`${
                  location.pathname === "/payments"
                    ? "text-[#FFAD00]"
                    : "text-gray-900"
                }  cursor-pointer flex items-center px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
              >
                <MdCardGiftcard className="h-[17px] w-[17px] text-[100px]" />
                <span className="ml-2 font-[700]"> Payments</span>
              </p>
              <a className="cursor-pointer flex items-center px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                <IoMdSettings className="h-[17px] w-[17px] text-[100px]" />

                <span className="ml-2 font-[700]">Update Subscription</span>
              </a>
              <p className="cursor-pointer flex items-center px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                <LuLogIn className="h-[17px] w-[17px] text-[100px]" />
                <span className="ml-2 font-[700]"> Logout</span>
              </p>
            </nav>
          </div>
        </div>
      </div>
      {/* <div className={drawerClasses}>
        <div
          className="flex-1 bg-gray-800 bg-opacity-50"
          onClick={toggleDrawer}
        ></div>
        <div className="w-64 bg-white"></div>
      </div> */}
    </React.Fragment>
  );
};

export default Header;
